import {
  getCheckedInputOfForm,
  getInputsOfForm,
  getTextAnswerByTask,
} from "./queries";
import {
  TASK_TYPES,
  TASK_TYPE_ATTRIBUTE,
  TASK_CORRECT_ANSWERS_ATTRIBUTE,
  TASK_INITIALIZE_ATTRIBUTE,
} from "./task-constants";

export function getLinkToTask(taskElement) {
  const sectionOfTask = taskElement.closest("section[id]");
  if (sectionOfTask) {
    return `#/${sectionOfTask.id}`;
  }
}

export function getNextTaskId() {
  const otherTasks = [...document.querySelectorAll("form[id^=task]")];
  if (otherTasks.length === 0) {
    return "task1";
  }
  return (
    "task" +
    otherTasks
      .map((el) => Number(el.id.split("task")[1]) + 1)
      .sort((a, b) => b - a)[0]
  );
}

export function getNextAnswerIdForTask(taskId) {
  const otherAnswers = [
    ...document.querySelectorAll(`form[id^=${taskId}] input`),
  ];
  const answerIds = otherAnswers
    .filter((el) => el.id.startsWith(taskId))
    .map((el) => Number(el.id.split("-answer")[1]))
    .sort((a, b) => b - a);
  let nextAnswerId = 0;
  if (answerIds.length > 0) {
    nextAnswerId = answerIds[0] + 1;
  }
  return buildTaskAnswerId(taskId, nextAnswerId);
}

function initializeManualTasks() {
  const manualForms = [
    ...document.querySelectorAll(`form[${TASK_INITIALIZE_ATTRIBUTE}]`),
  ];
  manualForms.forEach((manualForm) => {
    const taskId = getNextTaskId();
    manualForm.setAttribute("id", taskId);
    manualForm.setAttribute(
      "onsubmit",
      "App.Reveal.handleQuestionsSubmission(event)"
    );
    const answers = [...manualForm.querySelectorAll("input")];
    const labels = [...manualForm.querySelectorAll("label")];
    answers.forEach((answer) => {
      answer.setAttribute("name", taskId);
      answer.setAttribute("id", getNextAnswerIdForTask(taskId));
      answer.setAttribute(
        "onchange",
        "App.Submission.handleInputChange(event)"
      );
    });
    labels.forEach((label, idx) => {
      label.setAttribute("for", buildTaskAnswerId(taskId, idx));
    });
  });
}

window.initializeManualTasks = initializeManualTasks;

export function buildTaskAnswerId(taskId, idx) {
  return `${taskId}-answer${idx}`;
}

export function isLearnersAnswerCorrect(taskId) {
  const taskType =
    document.getElementById(taskId).attributes[TASK_TYPE_ATTRIBUTE].value;

  const learnersAnswers = getLearnersAnswersForTask(taskId);
  const correctAnswers = getCorrectAnswersForTask(taskId);

  const isTextTask = taskType === TASK_TYPES.TEXT;
  const isChoicedTask =
    taskType === TASK_TYPES.SINGLE_CHOICE ||
    taskType === TASK_TYPES.MULTIPLE_CHOICE;
  if (isTextTask) {
    return isTextAnswersCorrect(learnersAnswers, correctAnswers);
  } else if (isChoicedTask) {
    return isChoicedAnswersCorrect(learnersAnswers, correctAnswers);
  }
}

export function getLearnersAnswersForTask(taskId) {
  const inputType =
    document.getElementById(taskId).attributes[TASK_TYPE_ATTRIBUTE].value;
  if (inputType === TASK_TYPES.SINGLE_CHOICE) {
    return getAnswerForSingleChoiceTask(taskId);
  } else if (inputType === TASK_TYPES.MULTIPLE_CHOICE) {
    return getAnswersForMultipleChoiceTask(taskId);
  } else if (inputType === TASK_TYPES.TEXT) {
    return getAnswerForTextTask(taskId);
  }
}

export function getCorrectAnswersForTask(taskId) {
  return document
    .getElementById(taskId)
    .attributes[TASK_CORRECT_ANSWERS_ATTRIBUTE].value.split(",");
}

export function isChoicedAnswersCorrect(learnersAnswers, correctAnswers) {
  const learnersAnswersValues = learnersAnswers
    .filter((answer) => !!answer)
    .map((answer) => answer.value);
  if (learnersAnswersValues.length !== correctAnswers.length) {
    return false;
  }
  return correctAnswers.every((correctAnswer) =>
    learnersAnswersValues.includes(correctAnswer)
  );
}

export function isTextAnswersCorrect(learnersAnswers, correctAnswers) {
  if (
    learnersAnswers &&
    learnersAnswers.length > 0 &&
    correctAnswers &&
    correctAnswers.length > 0
  ) {
    return correctAnswers.some(
      (correctAnswer) =>
        correctAnswer.toLowerCase() ===
        learnersAnswers[0].value.trim().toLowerCase()
    );
  }
  return false;
}

function getAnswerForTextTask(taskId) {
  const textInput = getTextAnswerByTask(taskId);
  return [{ value: textInput.value, id: textInput.id }];
}

function getAnswersForMultipleChoiceTask(taskId) {
  const checkboxes = Array.from(getInputsOfForm(taskId));
  return checkboxes
    .map((input) => {
      if (input.checked) {
        return { value: input.value, id: input.id };
      }
    })
    .filter((value) => !!value);
}

function getAnswerForSingleChoiceTask(taskId) {
  const answer = getCheckedInputOfForm(taskId);
  if (answer) {
    return [
      {
        value: answer.value,
        id: answer.id,
      },
    ];
  } else {
    return [];
  }
}
