import ChapterTitleControls from "./ChapterTitleControls";
import TableOfContents from "./TableOfContents";
import SectionTabs from "./SectionTabs";
import { List, ListItem } from "./List";
import TableOfContentsIntro from "./TableOfContentsIntro";
import { Task, TaskAnswer } from "./Task";
import { Results } from "./Results";

customElements.define("chapter-title-controls", ChapterTitleControls);
customElements.define("chapter-toc", TableOfContents);
customElements.define("chapter-toc-intro", TableOfContentsIntro);
customElements.define("section-tabs", SectionTabs);
customElements.define("custom-list", List);
customElements.define("custom-list-item", ListItem);
customElements.define("chapter-task", Task);
customElements.define("chapter-task-answer", TaskAnswer);
customElements.define("module-results", Results);
