import {
  concatenateClasses,
  concatenateStyles,
  renderAttributeIfDefined,
} from "../utils/custom-elements";
import {
  TASK_CORRECT_ANSWERS_ATTRIBUTE,
  TASK_DESCRIPTION_ATTRIBUTE,
  TASK_EXPLANATION_ATTRIBUTE,
  TASK_FORM_CLASS_ATTRIBUTE,
  TASK_HIDE_SUBMIT_ATTRIBUTE,
  TASK_POINTS_ATTRIBUTE,
  TASK_TYPE_ATTRIBUTE,
} from "../utils/task-constants";
import { buildTaskAnswerId, getNextTaskId } from "../utils/task-utils";

const TASK_ANSWERS_CONTAINER = "chapter-task-answers";
const TASK_SUBMIT = "chapter-task-submit";
const TASK_TITLE = "chapter-task-title";
const TASK_DESCRIPTION = "chapter-task-description";
const TASK_ANSWER = "chapter-task-answer";

export class Task extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    if (this.isConnected) {
      this.answersContainer = this.getElementsByTagName(
        TASK_ANSWERS_CONTAINER
      )[0];
      this.titleElement = this.getElementsByTagName(TASK_TITLE)[0];
      this.submitButtonElement = this.getElementsByTagName(TASK_SUBMIT)[0];
      this.points = this.getAttribute(TASK_POINTS_ATTRIBUTE);
      this.explanation = this.getAttribute(TASK_EXPLANATION_ATTRIBUTE);
      this.correctAnswers = this.getAttribute(TASK_CORRECT_ANSWERS_ATTRIBUTE)
        .split(",")
        .map((answer) => answer.trim());
      this.formId = getNextTaskId();
      this.answers = this.getTaskAnswers();
      this.taskType = this.getAttribute(TASK_TYPE_ATTRIBUTE);
      this.formClass = this.getAttribute(TASK_FORM_CLASS_ATTRIBUTE);
      this.shouldShowSubmitButton = !this.hasAttribute(
        TASK_HIDE_SUBMIT_ATTRIBUTE
      );
      this.descriptionElement = this.getElementsByTagName(TASK_DESCRIPTION)[0];
      this.innerHTML = this.render();
    }
  }

  getTaskAnswers() {
    const answers = this.answersContainer.getElementsByTagName(TASK_ANSWER);
    if (answers) {
      return Array.from(answers).map((el, idx) => {
        el.taskId = this.formId;
        el.answerId = buildTaskAnswerId(this.formId, idx);
        return el;
      });
    }
  }

  renderTitle() {
    return `
    <h6
      ${renderAttributeIfDefined("class", this.titleElement?.className)}
      ${renderAttributeIfDefined(
        "style",
        concatenateStyles([this.titleElement?.style?.cssText, "color: #e60028"])
      )}
    >
      ${this.titleElement.innerHTML}
    </h6>
    `;
  }

  renderDescription() {
    return this.descriptionElement.innerHTML;
  }

  renderAnswers() {
    return `
    <div 
      ${renderAttributeIfDefined(
        "class",
        concatenateClasses([
          this.answersContainer?.classList,
          "container",
          "column",
        ])
      )}
      ${renderAttributeIfDefined(
        "style",
        concatenateStyles([
          this.answersContainer?.style?.cssText,
          "text-align: left",
          "margin-left: 8px",
          "flex-wrap: wrap",
          "align-content: center",
          "justify-items: flex-start",
        ])
      )}
      ${renderAttributeIfDefined("class", this.answersContainer?.className)}
    >
      ${this.answers.map((el) => el.render()).join("")}
    </div>
    `;
  }

  renderSubmitButton() {
    if (this.shouldShowSubmitButton) {
      return `
      <button 
        type="submit"
        class=${concatenateClasses([
          this.submitButtonElement?.classList,
          "button-submit",
        ])}
        ${renderAttributeIfDefined(
          "style",
          this.submitButtonElement?.style?.cssText
        )}
      >
        ${this.submitButtonElement.innerHTML}
      </button>`;
    }
    return "";
  }

  renderForm() {
    return `
    <form
      ${renderAttributeIfDefined("id", this.formId)}
      ${renderAttributeIfDefined(TASK_POINTS_ATTRIBUTE, this.points)}
      ${renderAttributeIfDefined(
        TASK_CORRECT_ANSWERS_ATTRIBUTE,
        this.correctAnswers.join(",")
      )}
      ${renderAttributeIfDefined(
        "onsubmit",
        "App.Submission.handleQuestionsSubmission(event)"
      )}
      ${renderAttributeIfDefined(
        TASK_DESCRIPTION_ATTRIBUTE,
        this.titleElement.textContent.trim()
      )}
      ${renderAttributeIfDefined(TASK_EXPLANATION_ATTRIBUTE, this.explanation)}
      ${renderAttributeIfDefined("class", this.formClass)}
      ${renderAttributeIfDefined(TASK_TYPE_ATTRIBUTE, this.taskType)}
    >
      ${this.renderAnswers()}
      ${this.renderSubmitButton()}
    </form>`;
  }

  render(event) {
    return `
        ${this.renderTitle()}
        ${this.renderDescription()}
        ${this.renderForm()}
    `;
  }
}

export class TaskAnswer extends HTMLElement {
  constructor() {
    super();
  }

  getClassForInput(inputType) {
    if (inputType === "radio" || inputType === "checkbox") {
      return "radio-button";
    }
    return "";
  }

  render(event) {
    const inputElement = this.getElementsByTagName("input")[0];
    if (inputElement) {
      inputElement.setAttribute("id", this.answerId);
      inputElement.setAttribute(
        "onchange",
        "App.Submission.handleInputChange(event)"
      );
      inputElement.setAttribute(
        "class",
        this.getClassForInput(inputElement.getAttribute("type"))
      );
      inputElement.setAttribute("name", this.taskId);
    }
    const labelElement = this.getElementsByTagName("label")[0];
    if (labelElement) {
      labelElement.setAttribute("for", this.answerId);
    }
    this.style = concatenateStyles(["max-width:60%", this.style.cssText]);
    return this.outerHTML;
  }
}
