import { getUnescapedChapterName } from "../utils/chapters";

class TableOfContents extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    if (this.isConnected) {
      if (this.hasAttribute("chapter-name")) {
        this.chapterName = this.getAttribute("chapter-name");
      }
      App.Reveal.on("ready", () => {
        this.#render();
      });
    }
  }

  #render(_) {
    if (!!this.chapterName) {
      this.innerHTML = `<h4>Inhaltsverzeichnis - ${getUnescapedChapterName(
        this.chapterName
      )}</h4><ul class="r-fit-text">${this.#getSubChapterAsStringList().join(
        ""
      )}</ul>`;
    } else {
      this.innerHTML = `<ul class="r-fit-text">${this.#getChapterAsStringList().join(
        ""
      )}</ul>`;
    }
  }

  #getGlobalChapters() {
    return document.querySelectorAll("section [chapter]");
  }

  #getChapterAsStringList() {
    const chaptersElements = this.#getGlobalChapters();
    const chapters = [];
    for (const chapter of chaptersElements.values()) {
      const header = document.querySelector(`#${chapter.id} .chapter-header`);
      const title = document.querySelector(`#${chapter.id} .chapter-title`);
      const subtitle = document.querySelector(
        `#${chapter.id} .chapter-subtitle`
      );
      chapters.push(
        `<li><a href="#${chapter.id}">${header.textContent}: ${title.textContent} - ${subtitle.textContent}</a></li>`
      );
    }
    return chapters;
  }

  #getSubChapters(chapter) {
    return document.querySelectorAll(`section [id^="${chapter}:"]`);
  }

  #getSubChapterAsStringList() {
    let chaptersElements = this.#getSubChapters(this.chapterName);
    const firstLevelChapters = [];
    for (const chapter of chaptersElements.values()) {
      const chapterText = chapter.id
        .replaceAll(`${this.chapterName}:`, "")
        .replaceAll("_", " ")
        .split(":")[0];
      if (firstLevelChapters.find((c) => c.chapterText === chapterText))
        continue;
      firstLevelChapters.push({ chapterId: chapter.id, chapterText });
    }
    const chapters = [];
    for (const firstLevelChapter of firstLevelChapters) {
      chapters.push(
        `<li><a href="#${firstLevelChapter.chapterId}">${firstLevelChapter.chapterText}</a></li>`
      );
    }
    return chapters;
  }
}
export default TableOfContents;
