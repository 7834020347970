export class List extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    if (this.isConnected) {
      this.items = Array.from(
        this.getElementsByTagName("custom-list-item")
      ).map((el) => el.outerHTML);

      if (this.hasAttribute("divider")) {
        this.items = this.items.join("<hr style='margin: 8px'>");
      } else {
        this.items = this.items.join("");
      }

      this.innerHTML = this.render();
    }
  }

  render(event) {
    return `<ul style="list-style-type: none; padding-left: 0; margin-top: 0">${this.items}</ul>`;
  }
}

export class ListItem extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    if (this.isConnected) {
      this.innerHTML = this.render();
    }
  }

  render(event) {
    return `<li style="padding: 8px; margin: 8px">${this.innerHTML}</li>`;
  }
}
