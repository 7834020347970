import { Results } from "./components/Results";
import { LEARN_MODULE_MODE, MODULE_MODES } from "./environment";
import { scormProcessSetValue, scormProcessTerminate } from "./scormfunctions";
import { createObjectsRecursiverly } from "./utils/object";
import {
  getAllAnswersOfAllTasks,
  getAllFormsWithAttribute,
  getAllSubmitButtonsOfTasks,
  getAllTasks,
} from "./utils/queries";
import {
  TASK_DESCRIPTION_ATTRIBUTE,
  TASK_POINTS_ATTRIBUTE,
} from "./utils/task-constants";
import {
  getCorrectAnswersForTask,
  getLearnersAnswersForTask,
  isLearnersAnswerCorrect,
} from "./utils/task-utils";

let submissionObject = {};
const SUBMISSION_OBJECT_KEY = "submissionObject";

window.loadSavedAnswersFromLocalStorage = function () {
  const storageSubmissionObject = localStorage.getItem(SUBMISSION_OBJECT_KEY);
  if (storageSubmissionObject) {
    submissionObject = JSON.parse(storageSubmissionObject);
    console.log("Loading answers from local storage", submissionObject);
    setInputsFromSubmissionObject(submissionObject);
  }
};

function setInputsFromSubmissionObject(object) {
  Object.keys(object)
    .filter((taskId) => {
      return object[taskId].answers && object[taskId].answers.length > 0;
    })
    .forEach((taskId) => {
      object[taskId].answers.forEach((answer) => {
        const { id: answerId, value: answerValue } = answer;
        const input = document.getElementById(answerId);
        if (input && answerValue) {
          const isChoicedInput =
            input.type === "radio" || input.type === "checkbox";
          if (isChoicedInput) {
            input.checked = answerValue;
          } else {
            input.value = answerValue;
          }
        }
      });
    });
}

export function handleQuestionsSubmission(event) {
  event.preventDefault();
  const task = event.target;
  const taskId = task.id;
  createObjectsRecursiverly(submissionObject, [taskId]);
  fillTaskObject(submissionObject, task);
  App.Reveal.next();
}

export function handleInputChange(event) {
  const task = event.target.form;
  if (task) {
    fillTaskObject(submissionObject, task);
    localStorage.setItem(
      SUBMISSION_OBJECT_KEY,
      JSON.stringify(submissionObject)
    );
  }
}

function fillTaskObject(objectToAddTo, taskElement) {
  const taskId = taskElement.id;
  createObjectsRecursiverly(objectToAddTo, [taskId, "answers"]);
  const validAnswer = isLearnersAnswerCorrect(taskId);
  const correctAnswer = getCorrectAnswersForTask(taskId).join(";");
  const learnersAnswers = getLearnersAnswersForTask(taskId);
  const taskDescription =
    taskElement.attributes[TASK_DESCRIPTION_ATTRIBUTE].value;
  const pointsForTask = taskElement.attributes[TASK_POINTS_ATTRIBUTE].value;
  const points = validAnswer ? pointsForTask : 0;
  objectToAddTo[taskId].points = points;
  objectToAddTo[taskId].id = taskId;
  objectToAddTo[taskId].taskDescription = taskDescription;
  objectToAddTo[taskId].correctAnswer = correctAnswer;
  submissionObject[taskId].answers = learnersAnswers;
  submissionObject[taskId].pointsForTask = pointsForTask;
  objectToAddTo[taskId].learnersAnswer = learnersAnswers
    .map((answer) => answer.value)
    .join(";");
  objectToAddTo[taskId].validAnswer = validAnswer;
}

function calculateLearnersPoints() {
  let learnersPoints = 0;

  const tasks = Object.values(submissionObject);
  for (const task of tasks) {
    learnersPoints += parseInt(task.points);
  }

  return learnersPoints;
}

function calculateMaxPoints() {
  const tasks = getAllFormsWithAttribute(TASK_POINTS_ATTRIBUTE);
  let maxPoints = 0;
  for (const task of tasks) {
    maxPoints += parseInt(task.attributes[TASK_POINTS_ATTRIBUTE].value);
  }

  return maxPoints;
}

function calculatePassedStatus(learnersPoints, maxPoints) {
  const relativePoints = getRelativePoints(learnersPoints, maxPoints);
  return relativePoints >= 50;
}

function renderResultsPage() {
  const results = new Results();
  document.querySelector(".slides").appendChild(results);
  results.render();
  App.Reveal.layout();
  App.Reveal.right();
}

function disableAllAnswersAndForms() {
  const answers = getAllAnswersOfAllTasks();
  const tasks = getAllTasks();
  const submitButtons = getAllSubmitButtonsOfTasks();
  for (const answer of answers) {
    answer.setAttribute("disabled", "true");
  }
  for (const task of tasks) {
    task.setAttribute("disabled", "true");
  }
  for (const submitButton of submitButtons) {
    submitButton.setAttribute("disabled", "true");
  }
}

function getRelativePoints(actualPoints, maxPoints) {
  return actualPoints * (100 / maxPoints);
}

export function handleFinalSubmission(event) {
  event.preventDefault();
  renderResultsPage();
  disableAllAnswersAndForms();
  const maxPoints = parseInt(calculateMaxPoints());
  const learnersPoints = parseInt(calculateLearnersPoints());
  const hasPassed = calculatePassedStatus(learnersPoints, maxPoints);

  console.log("Max Points: " + maxPoints);
  console.log("Learners Points: " + learnersPoints);
  console.log("Has Passed: " + hasPassed);

  const tasks = Object.keys(submissionObject);
  if (LEARN_MODULE_MODE === MODULE_MODES.LMS) {
    for (let i = 0; i < tasks.length; i++) {
      scormProcessSetValue(
        `cmi.interactions.${i}.id`,
        submissionObject[tasks[i]].id
      );
      scormProcessSetValue(`cmi.interactions.${i}.type`, "other");
      scormProcessSetValue(
        `cmi.interactions.${i}.description`,
        submissionObject[tasks[i]].taskDescription
      );
      scormProcessSetValue(
        `cmi.interactions.${i}.correct_responses.0.pattern`,
        submissionObject[tasks[i]].correctAnswer
      );
      scormProcessSetValue(
        `cmi.interactions.${i}.learner_response`,
        submissionObject[tasks[i]].learnersAnswer
      );
      scormProcessSetValue(
        `cmi.interactions.${i}.result`,
        submissionObject[tasks[i]].validAnswer ? "correct" : "incorrect"
      );
    }

    scormProcessSetValue("cmi.score.min", 0);
    scormProcessSetValue("cmi.score.max", maxPoints);
    scormProcessSetValue(
      "cmi.score.raw",
      getRelativePoints(learnersPoints, maxPoints)
    );
    scormProcessSetValue("cmi.completion_status", "completed");

    if (hasPassed) {
      scormProcessSetValue("cmi.success_status", "passed");
    } else {
      scormProcessSetValue("cmi.success_status", "failed");
    }

    alert("Deine Antworten wurden übermittelt");
    scormProcessTerminate();
  }
}
