export function renderAttributeIfDefined(attributeName, attributeValue) {
  return attributeValue ? `${attributeName}="${attributeValue}"` : "";
}

export const concatenateClasses = (...classes) => {
  const flatClasses = classes.flat();
  const filteredClasses = flatClasses.filter((singleClass) => !!singleClass);
  return filteredClasses.join(" ");
};

export const concatenateStyles = (...styles) => {
  const flatStyles = styles.flat();
  const filteredStyles = flatStyles.filter((style) => !!style);
  return filteredStyles.join(";");
};
