const CHAPTER_TITLE_CONTROLS_ATTRIBUTE_NAME = "chapter-title-controls";
const CHAPTER_TITLE_CONTROLS_LEFT_ATTRIBUTE_NAME = `${CHAPTER_TITLE_CONTROLS_ATTRIBUTE_NAME}-left`;
const CHAPTER_TITLE_CONTROLS_RIGHT_ATTRIBUTE_NAME = `${CHAPTER_TITLE_CONTROLS_ATTRIBUTE_NAME}-right`;
const CHAPTER_TITLE_CONTROLS_DOWN_ATTRIBUTE_NAME = `${CHAPTER_TITLE_CONTROLS_ATTRIBUTE_NAME}-down`;

const LEFT_ARROW = "&#8592;";
const RIGHT_ARROW = "&#8594;";
const DOWN_ARROW = "&#8595;";

const DEFAULT_LEFT_LABEL = `${LEFT_ARROW} Vorheriges Kapitel`;
const DEFAULT_RIGHT_LABEL = `Nächstes Kapitel ${RIGHT_ARROW}`;
const DEFAULT_DOWN_LABEL = `Kapitel starten <br/> ${DOWN_ARROW}`;
class ChapterTitleControls extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    if (this.isConnected) {
      this.style = `{
        min-width: 100%;
        position: absolute;
        min-height: 100%;
    }`;
      this.chapters = this.getAttribute("chapter-ids")?.split(",") || [];
      App.Reveal.on("ready", (appReadyEvent) => {
        this.#render(appReadyEvent);
        App.Reveal.on("slidechanged", (slideChangedEvent) =>
          this.#render(slideChangedEvent)
        );
      });
    }
  }

  #render(event) {
    const { currentSlide } = event;
    this.innerHTML = "";
    const section = document.getElementById(currentSlide?.id);
    const hasAnyControls = section
      ?.getAttributeNames()
      .some((attributeName) =>
        attributeName.startsWith(CHAPTER_TITLE_CONTROLS_ATTRIBUTE_NAME)
      );
    if (!hasAnyControls) {
      return;
    }

    const leftChapterControlLabel =
      section.getAttribute(CHAPTER_TITLE_CONTROLS_LEFT_ATTRIBUTE_NAME) ||
      DEFAULT_LEFT_LABEL;
    const rightChapterControlLabel =
      section.getAttribute(CHAPTER_TITLE_CONTROLS_RIGHT_ATTRIBUTE_NAME) ||
      DEFAULT_RIGHT_LABEL;
    const downChapterControlLabel =
      section.getAttribute(CHAPTER_TITLE_CONTROLS_DOWN_ATTRIBUTE_NAME) ||
      DEFAULT_DOWN_LABEL;

    const shouldShowLeftChapterControl = !!section.hasAttribute(
      CHAPTER_TITLE_CONTROLS_LEFT_ATTRIBUTE_NAME
    );
    const shouldShowRightChapterControl = !!section.hasAttribute(
      CHAPTER_TITLE_CONTROLS_RIGHT_ATTRIBUTE_NAME
    );
    const shouldShowDownChapterControl = !!section.hasAttribute(
      CHAPTER_TITLE_CONTROLS_DOWN_ATTRIBUTE_NAME
    );
    const availableRoutes = App.Reveal.availableRoutes();

    if (availableRoutes.right && shouldShowRightChapterControl) {
      this.innerHTML += this.renderControlButton(
        rightChapterControlLabel,
        "right"
      );
    }
    if (availableRoutes.down && shouldShowDownChapterControl) {
      this.innerHTML += this.renderControlButton(
        downChapterControlLabel,
        "down"
      );
    }
    if (availableRoutes.left && shouldShowLeftChapterControl) {
      this.innerHTML += this.renderControlButton(
        leftChapterControlLabel,
        "left"
      );
    }
  }

  renderControlButton(label, direction) {
    return `<button class="title-controls ${direction}" onclick="App.Reveal.${direction}()">
        ${label}
      </button>`;
  }
}
export default ChapterTitleControls;
