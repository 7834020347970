class TableOfContents extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    if (this.isConnected) {
      App.Reveal.on("ready", () => {
        this.#render();
      });
    }
  }

  #render(_) {
    this.innerHTML = `<ul class="r-fit-text" style="list-style-type:none; text-align:center">${this.#getChapterAsStringList().join(
      ""
    )}</ul>`;
  }

  #getGlobalChapters() {
    return document.querySelectorAll("section [chapter]");
  }

  #getChapterAsStringList() {
    const chaptersElements = this.#getGlobalChapters();
    const chapters = [];
    for (const chapter of chaptersElements.values()) {
      if (chapter.id !== "Einführung") {
        const title = document.querySelector(`#${chapter.id} .chapter-title`);
        chapters.push(
          `<li class="fragment" style="font-size:larger"><a href="#${chapter.id}">${title.textContent}</a></li>`
        );
      }
    }
    return chapters;
  }
}
export default TableOfContents;
