export const TASK_CORRECT_ANSWERS_ATTRIBUTE = "data-task-correct-answers";
export const TASK_TYPE_ATTRIBUTE = "data-task-type";
export const TASK_FORM_CLASS_ATTRIBUTE = "form-class";
export const TASK_HIDE_SUBMIT_ATTRIBUTE = "data-hide-submit-button";
export const TASK_POINTS_ATTRIBUTE = "data-task-points";
export const TASK_DESCRIPTION_ATTRIBUTE = "data-task-description";
export const TASK_INITIALIZE_ATTRIBUTE = "data-task-initialize";
export const TASK_EXPLANATION_ATTRIBUTE = "data-task-explanation";

export const TASK_TYPES = {
  SINGLE_CHOICE: "single-choice",
  MULTIPLE_CHOICE: "multiple-choice",
  TEXT: "text",
};
