import {
  concatenateClasses,
  renderAttributeIfDefined,
} from "../utils/custom-elements";
import {
  getAllTasks,
  getInputByValueOfForm,
  getInputsOfForm,
  getLabelForInput,
} from "../utils/queries";
import {
  TASK_CORRECT_ANSWERS_ATTRIBUTE,
  TASK_DESCRIPTION_ATTRIBUTE,
  TASK_EXPLANATION_ATTRIBUTE,
  TASK_TYPES,
  TASK_TYPE_ATTRIBUTE,
} from "../utils/task-constants";
import { getLinkToTask, isLearnersAnswerCorrect } from "../utils/task-utils";

export class Results extends HTMLElement {
  constructor() {
    super();
  }

  getLabelForAnswer(answerId) {
    const label = getLabelForInput(answerId);
    return label ? label.innerText : "";
  }

  getLabelForAnswerByValue(taskId, answerValue) {
    const answerInput = getInputByValueOfForm(taskId, answerValue);
    if (answerInput) {
      return this.getLabelForAnswer(answerInput.id);
    }
  }

  getGivenAnswersForTask(task) {
    const isChoicedTask =
      task.taskType === TASK_TYPES.MULTIPLE_CHOICE ||
      task.taskType === TASK_TYPES.SINGLE_CHOICE;
    const isTextTask = task.taskType === TASK_TYPES.TEXT;
    const answersOfTask = Array.from(getInputsOfForm(task.taskId));
    return answersOfTask
      .filter((answer) => {
        if (isChoicedTask) {
          return answer.checked;
        } else if (isTextTask) {
          return !!answer.value;
        }
      })
      .map((answer) =>
        isTextTask ? answer.value : this.getLabelForAnswer(answer.id)
      );
  }

  getCorrectAnswersForTask(task) {
    const rawCorrectAnswers = task.taskElement.getAttribute(
      TASK_CORRECT_ANSWERS_ATTRIBUTE
    );
    const isTextTask = task.taskType === TASK_TYPES.TEXT;
    return rawCorrectAnswers
      .split(",")
      .map((answerValue) =>
        isTextTask
          ? answerValue.split(",")
          : this.getLabelForAnswerByValue(task.taskId, answerValue)
      );
  }

  getTasksAndAnswers() {
    const tasksAnswers = [];
    const tasks = getAllTasks();
    for (const task of tasks) {
      const taskAnswer = {
        taskElement: task,
        taskId: task.id,
        taskTitle: task.getAttribute(TASK_DESCRIPTION_ATTRIBUTE),
        taskType: task.getAttribute(TASK_TYPE_ATTRIBUTE),
        isCorrect: isLearnersAnswerCorrect(task.id),
        givenAnswers: [],
        correctAnswers: [],
      };
      taskAnswer.givenAnswers = this.getGivenAnswersForTask(taskAnswer);
      taskAnswer.correctAnswers = this.getCorrectAnswersForTask(taskAnswer);
      tasksAnswers.push(taskAnswer);
    }
    return tasksAnswers;
  }

  renderAnswer(answer) {
    return html`
      <li>${answer}</li>
    `;
  }

  getRandomCorrectString() {
    const randomNumber = Math.floor(Math.random() * 3);
    switch (randomNumber) {
      case 0:
        return "Das ist richtig!";
      case 1:
        return "Das ist korrekt!";
      case 2:
        return "Sehr gut, das ist richtig!";
    }
  }

  getRandomIncorrectString() {
    const randomNumber = Math.floor(Math.random() * 3);
    switch (randomNumber) {
      case 0:
        return "Das ist leider falsch.";
      case 1:
        return "Das ist leider nicht richtig.";
      case 2:
        return "Das ist leider nicht korrekt.";
    }
  }

  getExplanationForTask(task) {
    const helpText = task.taskElement.getAttribute(TASK_EXPLANATION_ATTRIBUTE);
    console.log(task.taskId, task.isCorrect);
    if (task.isCorrect) {
      return `${this.getRandomCorrectString()} ${helpText ? helpText : ""}`;
    } else {
      return `${this.getRandomIncorrectString()} ${helpText ? helpText : ""}`;
    }
  }

  renderGivenAnswersForTask(task) {
    return html`
      <div
        ${renderAttributeIfDefined(
          "class",
          concatenateClasses("given-answers")
        )}
      >
        <div
          ${renderAttributeIfDefined(
            "class",
            concatenateClasses("answers-header")
          )}
        >
          Gewählte Antworten:
        </div>
        <ul>
          ${task.givenAnswers
            .map((answer) => this.renderAnswer(answer))
            .join("")}
        </ul>
      </div>
    `;
  }

  renderCorrectAnswersForTask(task) {
    return html`
      <div
        ${renderAttributeIfDefined(
          "class",
          concatenateClasses("correct-answers")
        )}
      >
        <div
          ${renderAttributeIfDefined(
            "class",
            concatenateClasses("answers-header")
          )}
        >
          Korrekte Antworten:
        </div>
        <ul>
          ${task.correctAnswers
            .map((answer) => this.renderAnswer(answer))
            .join("")}
        </ul>
      </div>
    `;
  }

  renderExplanationForTask(task) {
    return html`
      <div
        ${renderAttributeIfDefined("class", concatenateClasses("explanation"))}
      >
        <div
          ${renderAttributeIfDefined(
            "class",
            concatenateClasses("answers-header")
          )}
        >
          Erklärung:
        </div>
        <div>${this.getExplanationForTask(task)}</div>
      </div>
    `;
  }

  renderTask(task) {
    const linkToTask = getLinkToTask(task.taskElement);
    return html`
      <custom-list-item>
        <h6>
          <a href="#/${linkToTask}">${task.taskTitle}</a>
        </h6>
        ${this.renderGivenAnswersForTask(task)}
        ${this.renderCorrectAnswersForTask(task)}
        ${this.renderExplanationForTask(task)}
      </custom-list-item>
    `;
  }

  render() {
    this.outerHTML = html`
      <section>
        <section
          ${renderAttributeIfDefined(
            "class",
            concatenateClasses("scrollable", this.classList)
          )}
        >
          <div class="r-fit-text">
            <h5>Ergebnisevaluation</h5>
            <custom-list divider>
              ${this.getTasksAndAnswers()
                .map((task) => this.renderTask(task))
                .join("")}
            </custom-list>
          </div>
        </section>
      </section>
    `;
  }
}

function html(literals, ...vars) {
  let raw = literals.raw,
    result = "",
    i = 1,
    len = arguments.length,
    str,
    variable;

  while (i < len) {
    str = raw[i - 1];
    variable = vars[i - 1];
    result += str + variable;
    i++;
  }
  result += raw[raw.length - 1];
  return result;
}
