export function getLabelForInput(inputId) {
  return document.querySelector(`label[for="${inputId}"]`);
}

export function getInputByValueOfForm(formId, inputValue) {
  return document.querySelector(
    `form[id=${formId}] input[value="${inputValue}"]`
  );
}

export function getInputsOfForm(formId) {
  return document.querySelectorAll(
    `form[id^=${formId}] input[id^=${formId}-answer]`
  );
}

export function getCheckedInputOfForm(formId) {
  return document.querySelector(`[id^=${formId}-answer]:checked`);
}

export function getAllTasks() {
  return document.querySelectorAll("form[id^=task]");
}

export function getAllFormsWithAttribute(attribute) {
  return document.querySelectorAll(`form[${attribute}]`);
}

export function getTextAnswerByTask(taskId) {
  return document.querySelector(
    `form[id=${taskId}] input[id^=${taskId}-answer]`
  );
}

export function getAllAnswersOfAllTasks() {
  return document.querySelectorAll("form[id^=task] input[id*=-answer]");
}

export function getAllSubmitButtonsOfTasks() {
  return document.querySelectorAll("form[id^=task] button[type=submit]");
}
