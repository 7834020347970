class SectionTabs extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    if (this.isConnected) {
      this.linksContainer =
        this.getElementsByTagName("section-tab-links")?.item(0);
      this.contentsContainer = this.getElementsByTagName(
        "section-tab-contents"
      )?.item(0);
      const contents = Array.from(
        this.contentsContainer?.getElementsByTagName("section-tab-content")
      );
      this.tabs =
        Array.from(
          this.linksContainer?.getElementsByTagName("section-tab-link")
        ).map((el) => ({
          link: el.getAttribute("href"),
          label: el.getAttribute("label"),
          content: contents.find(
            (content) =>
              content.getAttribute("tab") === el.getAttribute("label")
          )?.innerHTML,
        })) || [];
      this.header = this.getElementsByTagName("section-tab-header")?.item(0);
      this.outerHTML = this.renderSectionTabs();
    }
  }

  renderSectionTabs() {
    const tabSections = this.tabs.map((tab, tabIndex) => {
      const sectionTab = this.renderSectionTab(tab, tabIndex);
      return sectionTab.outerHTML;
    });
    return tabSections.join("");
  }

  renderSectionTab(tab, tabIndex) {
    const section = document.createElement("section");
    section.setAttribute("id", tab.link);
    section.setAttribute(
      "data-transition",
      this.getTransitionForPosition(tabIndex)
    );
    const tabElements = this.tabs.map((otherTab) => {
      const active = otherTab.label === tab.label ? "active" : "";
      return `<a href="#/${otherTab.link}">
      <button class="${active}">${otherTab.label}</button>
      </a>`;
    });
    const headerHtml = this.header
      ? `<h4 style="margin-bottom: 55px">${this.header?.innerHTML}</h4>`
      : "";
    const tabsHtml = `<div class="tab">${tabElements.join("")}</div>`;
    const contentHtml = `<div class="tabcontent">${tab.content}</div>`;
    section.innerHTML = `${headerHtml}${tabsHtml}${contentHtml}`;
    return section;
  }
  getTransitionForPosition(position) {
    if (position > 0) {
      return "none";
    } else {
      return "slide-in none-out";
    }
  }
}
export default SectionTabs;
